var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bg-firstvet-primary flex flex-row flex-shrink-0 sm:flex-col h-12 sm:h-full sm:w-16 text-white w-full"
    },
    [
      _c(
        "router-link",
        { class: { disabled: !_vm.token }, attrs: { to: "/" } },
        [
          _c(
            "div",
            {
              staticClass:
                "bg-firstvet-primarydark flex justify-center items-center h-12 w-12 sm:h-16 sm:w-16 flex-shrink-0"
            },
            [
              _c("img", {
                staticClass: "p-2 sm:p-4",
                attrs: { src: require("@/assets/firstvet-logo.png") }
              })
            ]
          )
        ]
      ),
      _vm.token ? _c("logout-button") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }