import Home from '@/views/Home.vue';
import Room from '@/views/Room.vue';

export default [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        auth: true,
    },
  },
  {
    path: '/room/:id?',
    name: 'room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "room" */ '@/views/Room.vue'),
      meta: {
        guest: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "room" */ '@/views/Login.vue'),
      meta: {
        guest: true,
    },
  },
  { path: '*', redirect: '/' }
];
