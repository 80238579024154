<template>
  <component 
    :is="type" 
    :to="to"
    class="btn secondary-btn"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
import BaseButton from './BaseButton';

export default {
  extends: BaseButton,
};
</script>
<style lang="postcss" scoped>
.secondary-btn {
  @apply bg-teal-500 text-white tracking-wide uppercase;
}
.secondary-btn:hover {
  @apply bg-teal-600;
}
</style>
