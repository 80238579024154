<template>
  <div class="text-center flex flex-col lg:w-2/5 md:w-1/2 w-full">
    <h1 class="text-xl font-black mb-12">
      Start video call
    </h1>
    <label for="phone">Send invite to pet owner:</label>
    <div class="mt-4">
      <div class="flex flex-col md:flex-row">
        <select
          v-model="selectedCountryCode"
          :disabled="!countryCodes || !countryCodes.length"
          class="md:mr-4 my-1 h-12 w-full md:w-40 bg-white country-code-select"
        >
          <option
            v-if="!countryCodes || !countryCodes.length"
            :value="null"
          >
            Loading...
          </option>
          <option
            v-for="country in countryCodes"
            v-else
            :key="country.id"
            :value="country"
          >
            {{ `+${country.code} (${(country.country)})` }}
          </option>
        </select>
        <secondary-input
          id="phone"
          v-model.trim="phone_nr"
          placeholder="Phone number"
          name="phone"
          type="tel"
          autofocus
          @keyup.native.enter="createRoom()"
        />
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
    >
      <font-awesome-icon
        icon="exclamation-circle"
        class="mr-4 ml-2 text-lg self-center text-red-500"
      />
      <p class="self-center text-base">
        {{ errorMessage }}
      </p>
    </div>
    <primary-button
      class="mt-3 flex justify-between items-center"
      :disabled="loading"
      @click="createRoom()"
    >
      Start video call
      <base-spinner :loading="loading" />
      <font-awesome-icon
        v-if="!loading"
        icon="angle-right"
      />
    </primary-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppointmentService from '@/services/appointment-service';

export default {
  data() {
    return {
      phone_nr: '',
      selectedCountryCode: null,
      errorMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters('client', {
      countryCodes: 'getCountryCodes',
    }),
  },

  mounted() {
    if (!this.countryCodes.length) {
      this.getCountryCodes().then(() => {
        // Set Germany by default
        this.setSelectedCountryCode('49');
      });
    } else this.setSelectedCountryCode('49');
  },

  methods: {
    ...mapActions('client', ['getCountryCodes']),
    setSelectedCountryCode(code) {
      const selected = this.countryCodes.find(cc => cc.code === code);
      this.selectedCountryCode = selected;
    },
    validateNumber() {
      this.errorMessage = '';

      const isNumber = /^\d+$/.test(this.phone_nr);
      if (this.phone_nr === '' || !isNumber || this.phone_nr.length < 5) {
        if (this.phone_nr === '')
          this.errorMessage = 'The phone number input cannot be empty.';
        else if (!isNumber)
          this.errorMessage = 'The phone number must be in digits.';
        else if (this.phone_nr.length < 5)
          this.errorMessage = 'The phone number must be at least 5 digits';
        return false;
      }
      return true;
    },
    createRoom() {
      if (!this.validateNumber()) return;
      this.loading = true;

      AppointmentService.create({
        mobile_phone: this.phone_nr,
        country_code_id: this.selectedCountryCode.id,
        code: this.selectedCountryCode.code,
      })
        .then(res => {
          this.$router.push({
            name: 'room',
            params: {
              id: res.data.appointment.room,
            },
          });
        })
        .catch(error => {
          if (error.response) {
            this.errorMessage = error.response.data.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.country-code-select {
  border: 1px solid #e2e8ef;
}
</style>
