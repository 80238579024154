import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faHome,
  faUser,
  faAngleRight,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faDownload,
  faExclamationCircle,
  faInfoCircle,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  fas,
  faHome,
  faUser,
  faAngleRight,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faDownload,
  faExclamationCircle,
  faInfoCircle,
  faCommentDots
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
