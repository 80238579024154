import Vue from 'vue';
import Vuex from 'vuex';
import authStore from './modules/authStore';
import clientStore from './modules/clientStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      ...authStore,
      namespaced: true,
    },
    client: {
      ...clientStore,
      namespaced: true,
    }
  },
  state: {},
  mutations: {},
  actions: {},
});
