/* eslint-disable import/prefer-default-export */
export function promisePoll(
  promiseFunction,
  { pollIntervalMs = 2000, maxAttempts = 60 } = {}
) {
  const startPoll = async (resolve, reject, attempt) => {
    if (attempt >= maxAttempts) {
      return reject();
    }

    const startTime = new Date();
    const result = await promiseFunction();

    if (result) return resolve();

    const timeUntilNext = Math.max(
      pollIntervalMs - (new Date() - startTime),
      0
    );

    return setTimeout(
      () => startPoll(resolve, reject, attempt + 1),
      timeUntilNext
    );
  };

  return new Promise((resolve, reject) => startPoll(resolve, reject, 0));
}
