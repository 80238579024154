var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass:
      "w-full border-b-2 border-primary py-3 px-1 focus:outline-none focus:border-primary-darker focus:bg-grey-lightest font-medium leading-tight",
    attrs: { placeholder: _vm.placeholder, type: _vm.type },
    domProps: { value: _vm.value },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }