<script>
export default {
  props: {
    // Should be a VueRouter-object.
    to: {
      type: Object,
      default: () => {},
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    // Dynamically render component as either a router-link or button
    type() {
      return this.to ? 'router-link' : 'button';
    },
  },
  render: h => h,
};
</script>

<style lang="postcss">
.btn {
  @apply px-4 py-2 text-center rounded font-black;
}
</style>
