import axios from 'axios';
import AuthService from '@/services/auth-service';

// ===
// Auth helpers
// ===

function getSavedState(key) {
  return localStorage.getItem(key);
}

function saveState(key, state) {
  localStorage.setItem(key, state);
}

function removeState(key) {
  localStorage.removeItem(key);
}

function setDefaultAuthHeaders(state) {
  console.log('Setting axios default header Authorization: ', state.token);
  console.log('Setting axios default header Locale: ', state.locale);
  axios.defaults.headers.common.Authorization = state.token
    ? `Bearer ${state.token}`
    : '';
  axios.defaults.headers.common['Accept-Language'] = state.locale;
}

export default {
  state: {
    token: getSavedState('token') || '',
    locale: getSavedState('locale') || '',
    unauthorized: false,
  },
  getters: {
    getToken: state => state.token,
    getUnauthorized: state => state.unauthorized,
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setLocale: (state, payload) => {
      state.locale = payload;
    },
    setUnauthorized: (state, payload) => {
      state.unauthorized = payload;
    },
  },
  actions: {
    login({ commit, state }, payload) {
      return AuthService.login(payload)
        .then(res => {
          console.log('User logged in: ', res.data);
          commit('setToken', res.data.token);
          saveState('token', res.data.token);
          setDefaultAuthHeaders(state);
          commit('setLocale', res.data.locale);
          saveState('locale', res.data.locale);
          return res;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        removeState('token');
        commit('setToken', '');
        resolve('Logged out');
      });
    },
    setUnauthorized({ commit }, payload) {
      commit('setUnauthorized', payload);
    },
  },
};
