<template>
  <font-awesome-icon
    v-if="loading"
    :size="size"
    icon="circle-notch"
    spin
  />
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
};
</script>
