<template>
  <div
    id="app"
    class="text-grey-800 bg-grey-100 font-sans"
  >
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.caluclateVh();
    window.addEventListener('resize', this.caluclateVh);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.caluclateVh);
  },

  methods: {
    caluclateVh() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
</style>
