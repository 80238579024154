var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-full flex-col sm:flex-row" },
    [
      _c("navbar"),
      _vm.smsStatus && _vm.token
        ? _c(
            "h2",
            {
              staticClass:
                "sm:bg-grey-800 text-white text-center p-4 border-grey-600 sm:border \n    flex justify-center items-center absolute z-50 sm:h-16 h-12 ml-12 sm:ml-16 sm:bottom-0"
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-1",
                attrs: { icon: "comment-dots" }
              }),
              _vm._v("\n    Text message status: "),
              _c("em", { staticClass: "pl-1" }, [_vm._v(_vm._s(_vm.smsStatus))])
            ],
            1
          )
        : _vm._e(),
      _vm.showTosModal
        ? _c("base-modal", { attrs: { "show-close-button": false } }, [
            _c(
              "div",
              [
                _c("h2", { staticClass: "font-semibold text-lg mb-1" }, [
                  _vm._v(
                    "\n        Terms of Service and Privacy Policy\n      "
                  )
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "font-bold text-sm text-grey-700 hover:text-grey-800 cursor-pointer w-full text-left",
                    on: {
                      click: [
                        function($event) {
                          _vm.showTerms = true
                        },
                        function($event) {
                          $event.stopPropagation()
                        }
                      ]
                    }
                  },
                  [_vm._v("\n        Read Terms of Service\n      ")]
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "font-bold text-sm text-grey-700 hover:text-grey-800 cursor-pointer",
                    on: {
                      click: [
                        function($event) {
                          _vm.showPrivacy = true
                        },
                        function($event) {
                          $event.stopPropagation()
                        }
                      ]
                    }
                  },
                  [_vm._v("\n        Read Privacy Policy\n      ")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n        By using this service you agree to the Terms of Service and Privacy Policy.\n      "
                  )
                ]),
                _c(
                  "primary-button",
                  {
                    staticClass: "mt-4",
                    on: {
                      click: function($event) {
                        _vm.showTosModal = false
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        I agree to the Terms of Service and Privacy Policy\n      "
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.showTerms || _vm.showPrivacy
        ? _c(
            "base-modal",
            {
              on: {
                close: function($event) {
                  ;(_vm.showTerms = false), (_vm.showPrivacy = false)
                }
              }
            },
            [
              _c("h2", { staticClass: "font-bold text-xl" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.showTerms
                        ? _vm.termsOfService.text
                        : _vm.privacyPolicy.text
                    ) +
                    "\n    "
                )
              ]),
              _c(
                "div",
                { staticClass: "mt-5 terms-service max-h-full flex relative" },
                [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.showTerms
                          ? _vm.termsOfService.content
                          : _vm.privacyPolicy.content
                      )
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-full relative", attrs: { id: "otEmbedContainer" } },
        [
          _vm.loading || _vm.error
            ? _c(
                "div",
                {
                  staticClass: "flex justify-center items-center w-full h-full"
                },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "progress-spinner" })
                    : _vm._e(),
                  _vm.error
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-red-100 border-red-300 border-t-4 px-4 py-3 rounded-b shadow-md text-teal-900",
                          attrs: { role: "alert" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-center justify-around" },
                            [
                              _c(
                                "div",
                                { staticClass: "w-10" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "exclamation-triangle" }
                                  })
                                ],
                                1
                              ),
                              _c("div", { staticClass: "flex-grow" }, [
                                _c("p", { staticClass: "font-bold" }, [
                                  _vm._v("\n              Error\n            ")
                                ]),
                                _c("p", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.error) +
                                      "\n            "
                                  )
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }