<template>
  <div
    class="bg-firstvet-primary flex flex-row flex-shrink-0 sm:flex-col h-12 sm:h-full sm:w-16 text-white w-full"
  >
    <router-link
      :to="'/'"
      :class="{'disabled' : !token}"
    >
      <div class="bg-firstvet-primarydark flex justify-center items-center h-12 w-12 sm:h-16 sm:w-16 flex-shrink-0">
        <img
          class="p-2 sm:p-4"
          :src="require('@/assets/firstvet-logo.png')"
        >
      </div>
    </router-link>

    <logout-button v-if="token" />
  </div>
</template>

<script>
import LogoutButton from '@/components/LogoutButton';
import { mapGetters } from 'vuex';

export default {
  components: {
    LogoutButton,
  },

  computed: {
    ...mapGetters('auth', {
      token: 'getToken',
    }),
  },
};
</script>
<style scoped>
.disabled {
  pointer-events: none;
}
</style>
