<template>
  <label
    class="block text-grey-darker font-bold mb-2 select-none"
    :for="name"
    :class="'text-' + textSize"
  >
    <slot>Label</slot>
    <span
      v-if="mandatory"
      class="text-red-600 font-bold mb-2 select-none"
    >
      {{ `*` }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    textSize: {
      type: String,
      required: false,
      default: 'sm',
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
</style>
