import Vue from 'vue';
import './plugins/fontawesome';
import './plugins/axios';
import './UI/fv-ui';
import './directives';
import router from '@/router/router';
import store from '@/store/store';
import App from './App.vue';
import './assets/tailwind.postcss';

require('dotenv').config();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
