var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-grey-800 bg-grey-100 font-sans",
      attrs: { id: "app" }
    },
    [_c("router-view", { key: _vm.$route.fullPath })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }