<template>
  <button
    class="firstvet-nav-button bg-transparent flex justify-center items-center text-white
    m-0 w-12 h-12 sm:w-16 sm:h-16 ml-auto sm:mt-auto sm:ml-0
    hover:bg-firstvet-primarydark focus:bg-firstvet-primarydark active:bg-firstvet-primarydark"
    type="button"
    @click="logoutVet"
  >
    <font-awesome-icon
      icon="sign-out-alt"
      lg
    />
  </button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('auth', ['logout']),
    logoutVet() {
      this.logout().then(() => {
        console.log('vet logged out');
        this.$router.push('/login');
      });
    },
  },
};
</script>
<style lang="postcss">
.firstvet-nav-button {
  @apply text-lg font-bold p-3;
  transition: color 0.3s ease-in;
  transition: background-color 0.3s ease-in;
}
</style>
