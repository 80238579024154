import Vue from 'vue';
import axios from 'axios';
import store from '@/store/store';
import router from '../router/router';

const isProd = process.env.NODE_ENV === 'production';

const AUTH_TOKEN = store.state.auth.token || localStorage.getItem('token');
if (AUTH_TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

const apiURL =
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_BASE_URL;

const config = {
  baseURL: apiURL,
  timeout: 60 * 1000, // Timeout
  withCredentials: false,
};

/* eslint-disable */
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    return new Promise((resolve, reject) => {
      const e = error.response;
      console.error('Error caught', e);

      if (e.request.status === 401 && e.config) {
        // logout user if unathorized or token expired - .
        store.dispatch('auth/logout');
        store.dispatch('auth/setUnauthorized', true);

        router.push('/login');

        reject(e);
      }
      throw error;
    });
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
