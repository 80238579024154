var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass:
      "appearance-none border my-1 rounded w-full py-3 px-3 text-grey-darker\n  font-semibold leading-tight focus:outline-none focus:shadow-outline",
    attrs: {
      type: _vm.type,
      placeholder: _vm.placeholder,
      "data-vv-delay": "800"
    },
    domProps: { value: _vm.value },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }