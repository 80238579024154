<template>
  <div class="flex h-full flex-col sm:flex-row">
    <navbar />
    <div class="flex justify-center items-center w-full">
      <sms-component
        class="m-5"
      />
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar';
import SmsComponent from '@/components/SendSmsComponent';

export default {
  components: {
    Navbar,
    SmsComponent,
  },
};
</script>
