const guards = {
  requireAuth(to, from, next) {
    const isLoggedIn = localStorage.getItem('token');

    if (to.matched.some(record => record.meta.auth)) {
      if (!isLoggedIn) {
        next({
          path: '/login',
        });
      } else {
        next();
      }
    } else if (to.name === 'login' && isLoggedIn) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  },
};

export default guards;
