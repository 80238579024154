var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "firstvet-nav-button bg-transparent flex justify-center items-center text-white\n  m-0 w-12 h-12 sm:w-16 sm:h-16 ml-auto sm:mt-auto sm:ml-0\n  hover:bg-firstvet-primarydark focus:bg-firstvet-primarydark active:bg-firstvet-primarydark",
      attrs: { type: "button" },
      on: { click: _vm.logoutVet }
    },
    [_c("font-awesome-icon", { attrs: { icon: "sign-out-alt", lg: "" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }