<template>
  <div class="flex h-full flex-col sm:flex-row">
    <navbar />
    <h2
      v-if="smsStatus && token"
      class="sm:bg-grey-800 text-white text-center p-4 border-grey-600 sm:border 
      flex justify-center items-center absolute z-50 sm:h-16 h-12 ml-12 sm:ml-16 sm:bottom-0"
    >
      <font-awesome-icon
        icon="comment-dots"
        class="mr-1"
      />
      Text message status: <em class="pl-1">{{ smsStatus }}</em>
    </h2>
    <base-modal
      v-if="showTosModal"
      :show-close-button="false"
    >
      <div>
        <h2 class="font-semibold text-lg mb-1">
          Terms of Service and Privacy Policy
        </h2>
      
        <button
          class="font-bold text-sm text-grey-700 hover:text-grey-800 cursor-pointer w-full text-left"
          @click="showTerms = true"
          @click.stop
        >
          Read Terms of Service
        </button>
        <button
          class="font-bold text-sm text-grey-700 hover:text-grey-800 cursor-pointer"
          @click="showPrivacy = true"
          @click.stop
        >
          Read Privacy Policy
        </button>
        <p class="mt-2">
          By using this service you agree to the Terms of Service and Privacy Policy.
        </p>
        <primary-button
          class="mt-4"
          @click="showTosModal = false"
        >
          I agree to the Terms of Service and Privacy Policy
        </primary-button>
      </div>
    </base-modal>
    <base-modal
      v-if="showTerms || showPrivacy"
      @close="showTerms = false, showPrivacy = false"
    >
      <h2 class="font-bold text-xl">
        {{ showTerms ? termsOfService.text : privacyPolicy.text }}
      </h2>
  
      <div class="mt-5 terms-service max-h-full flex relative">
        <div v-html="showTerms ? termsOfService.content : privacyPolicy.content" />
      </div>
    </base-modal>
    <div
      id="otEmbedContainer"
      class="w-full relative"
    >
      <div
        v-if="loading || error"
        class="flex justify-center items-center w-full h-full"
      >
        <div
          v-if="loading"
          class="progress-spinner"
        />
        <div
          v-if="error"
          class="bg-red-100 border-red-300 border-t-4 px-4 py-3 rounded-b shadow-md text-teal-900"
          role="alert"
        >
          <div class="flex items-center justify-around">
            <div class="w-10">
              <font-awesome-icon
                icon="exclamation-triangle"
              />
            </div>
            <div class="flex-grow">
              <p class="font-bold">
                Error
              </p>
              <p class="text-sm">
                {{ error }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Navbar from '@/components/Navbar';
import AppointmentService from '@/services/appointment-service';
import { promisePoll } from '@/utils/helper-functions';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Navbar,
  },

  data() {
    return {
      embedId: process.env.VUE_APP_TOKBOX_EMBED_ID,
      loading: true,
      error: '',
      smsStatus: '',
      smsInProgress: false,
      checkingSmsStatus: false,
      showTosModal: false,
      showTerms: false,
      showPrivacy: false,
    };
  },

  computed: {
    ...mapGetters('auth', {
      isLoggedIn: 'getToken',
      token: 'getToken',
    }),
    ...mapGetters('client', {
      termsOfService: 'getTermsOfService',
      privacyPolicy: 'getPrivacyPolicy',
    }),
  },

  async mounted() {
    const room = this.$route.params.id;

    if (!room) {
      this.loading = false;
      this.error = 'Missing room param';
      return;
    }

    await this.getTermsOfService();
    await this.getPrivacyPolicy();

    const isValid = await this.validateRoom(room);

    if (!isValid) {
      return;
    }

    this.embedTokbox(room);

    // Check sms status for logged in users.
    if (this.isLoggedIn && this.smsInProgress) {
      try {
        await promisePoll(() => this.checkSmsStatus(room), {
          pollIntervalMs: 5000,
          maxAttempts: 60,
        });
      } catch (_) {
        this.smsStatus = 'timeout';
        this.smsInProgress = false;
      }
    }
    if (!this.isLoggedIn) this.showTosModal = true;
  },
  methods: {
    ...mapActions('client', ['getTermsOfService', 'getPrivacyPolicy']),
    validateRoom(room) {
      return AppointmentService.validate(room)
        .then(res => {
          this.smsStatus = res.data.sms_status;
          this.error = res.data.reason;
          this.smsInProgress = res.data.sms_in_progress;

          return res.data.valid;
        })
        .catch(error => {
          this.error =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';

          return false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkSmsStatus(room) {
      this.checkingSmsStatus = true;

      return AppointmentService.checkSmsStatus(room)
        .then(res => {
          this.smsStatus = res.data.sms_status;
          this.smsInProgress = res.data.sms_in_progress;

          return !this.smsInProgress;
        })
        .catch(error => {
          this.error =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'Server error';

          return true;
        })
        .finally(() => {
          this.checkingSmsStatus = false;
        });
    },

    embedTokbox(room) {
      const tokboxEmbedScript = document.getElementById('tokboxEmbed');

      if (tokboxEmbedScript) {
        tokboxEmbedScript.remove();
      }

      const tokboxEmbed = document.createElement('script');
      tokboxEmbed.setAttribute('id', 'tokboxEmbed');
      tokboxEmbed.setAttribute(
        'src',
        `https://tokbox.com/embed/embed/ot-embed.js?embedId=${
          this.embedId
        }&room=${room}`
      );
      document.body.appendChild(tokboxEmbed);

      // Dispatch DOMContent loaded event to make tokbox initialize
      tokboxEmbed.onload = () => {
        const DOMContentLoadedEvent = document.createEvent('Event');
        DOMContentLoadedEvent.initEvent('DOMContentLoaded', true, true);
        window.document.dispatchEvent(DOMContentLoadedEvent);
        this.loading = false;
      };

      tokboxEmbed.onerror = () => {
        this.loading = false;
        this.error = 'Could not load video chat embed';
      };
    },
  },
};
</script>

<style lang="postcss">
#otEmbedContainer {
  height: calc(100% - 3rem);
}

@screen sm {
  #otEmbedContainer {
    @apply h-full;
  }
}

.Embed .Embed-main {
  margin: 0;
}

.Embed .Embed-mask {
  background: transparent;
}

#start {
  @apply bg-firstvet-pink text-white tracking-wide py-3 rounded font-black font-sans;
}

#start:hover {
  @apply opacity-75;
}

#start {
  text-transform: lowercase;
}

#start:first-letter {
  text-transform: uppercase;
}

.progress-spinner,
.Embed progress-spinner {
  font-size: 50px;
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 1px solid transparent;
  border-top-color: #f097a4 !important;
  border-radius: 50%;
  animation: rotate 0.8s linear infinite;
}

.Embed .OT_fit-mode-cover .OT_video-element {
  object-fit: contain;
}

.terms-service p {
  @apply mb-4;
}

.terms-service h2 {
  @apply text-base font-black;
}

.terms-service h2:first-child {
  @apply text-2xl font-black;
}

.terms-service strong {
  @apply text-base font-black;
}
</style>
