
<template>
  <transition
    name="fade"
    mode="out-in"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @afterEnter="afterEnter"
  >
    <div
      v-if="show"
      class="z-50 fixed lg:px-4 p-0 pb-4 inset-0 flex items-center justify-center"
    >
      <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div
        v-if="show"
        class="fixed inset-0 transition-opacity z-20"
      >
        <div
          class="absolute inset-0 bg-smoke opacity-75"
          @click="$emit('close')"
        />
      </div>
      <div
        v-if="show"
        class="bg-white rounded-lg shadow-xl flex flex-col justify-center
        transform transition-all lg:max-w-3xl max-w-full w-full relative z-50"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          class="flex flex-col overflow-hidden"
          style="max-height:85vh;"
        >
          <div class="p-4 overflow-auto">
            <slot />
          </div>
        </div>
        <div
          v-if="showCloseButton"
          class="bg-gray-200 px-4 py-3 sm:px-6 flex w-full space-x-2 justify-end"
        >
          <slot
            class="contents"
            name="buttons"
          >
            <primary-button
              v-if="showCloseButton"
              @click="$emit('close')"
            >
              Close
            </primary-button>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { fadeTransitions } from './transitions';

export default {
  mixins: [fadeTransitions],

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  beforeDestroy() {
    this.$emit('close');
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
