<template>
  <input
    :value="value"
    :placeholder="placeholder"
    :type="type"
    class="w-full border-b-2 border-primary py-3 px-1 focus:outline-none focus:border-primary-darker focus:bg-grey-lightest font-medium leading-tight"
    @input="$emit('input', $event.target.value)" 
  >
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type here',
    },
    value: {
      type: String,
      required: false,
    },
  },
};
</script>
