<template>
  <input
    :value="value"
    class="appearance-none border my-1 rounded w-full py-3 px-3 text-grey-darker
    font-semibold leading-tight focus:outline-none focus:shadow-outline"
    :type="type"
    :placeholder="placeholder"
    data-vv-delay="800"
    @input="$emit('input', $event.target.value)"
  >
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type here',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
