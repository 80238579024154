var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex h-full flex-col sm:flex-row" },
    [
      _c("navbar"),
      _c(
        "div",
        { staticClass: "flex justify-center items-center w-full" },
        [_c("sms-component", { staticClass: "m-5" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }