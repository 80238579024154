export default {
  create(payload) {
    return axios.post('/api/d2c-appointment', payload);
  },

  validate(room) {
    return axios.get('/api/d2c-appointment/is-valid', { params: { room } });
  },

  checkSmsStatus(room) {
    return axios.get('/api/d2c-appointment/sms-status', { params: { room } });
  },
};
