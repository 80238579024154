var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade", mode: "out-in" },
      on: {
        beforeLeave: _vm.beforeLeave,
        enter: _vm.enter,
        afterEnter: _vm.afterEnter
      }
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              staticClass:
                "z-50 fixed lg:px-4 p-0 pb-4 inset-0 flex items-center justify-center"
            },
            [
              _vm.show
                ? _c(
                    "div",
                    { staticClass: "fixed inset-0 transition-opacity z-20" },
                    [
                      _c("div", {
                        staticClass: "absolute inset-0 bg-smoke opacity-75",
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.show
                ? _c(
                    "div",
                    {
                      staticClass:
                        "bg-white rounded-lg shadow-xl flex flex-col justify-center\n      transform transition-all lg:max-w-3xl max-w-full w-full relative z-50",
                      attrs: {
                        role: "dialog",
                        "aria-modal": "true",
                        "aria-labelledby": "modal-headline"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex flex-col overflow-hidden",
                          staticStyle: { "max-height": "85vh" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "p-4 overflow-auto" },
                            [_vm._t("default")],
                            2
                          )
                        ]
                      ),
                      _vm.showCloseButton
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-gray-200 px-4 py-3 sm:px-6 flex w-full space-x-2 justify-end"
                            },
                            [
                              _vm._t("buttons", [
                                _vm.showCloseButton
                                  ? _c(
                                      "primary-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("close")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            Close\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }