import Vue from 'vue';

import BaseSpinner from '@/UI/BaseSpinner';
import PrimaryButton from '@/UI/buttons/PrimaryButton';
import SecondaryButton from '@/UI/buttons/SecondaryButton';
import BaseInput from '@/UI/inputs/BaseInput';
import SecondaryInput from '@/UI/inputs/SecondaryInput';
import BaseLabel from '@/UI/inputs/BaseLabel';
import BaseModal from '@/UI/BaseModal';

Vue.component('base-spinner', BaseSpinner);
Vue.component('primary-button', PrimaryButton);
Vue.component('secondary-button', SecondaryButton);
Vue.component('base-input', BaseInput);
Vue.component('secondary-input', SecondaryInput);
Vue.component('base-label', BaseLabel);
Vue.component('base-modal', BaseModal);
