var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "block text-grey-darker font-bold mb-2 select-none",
      class: "text-" + _vm.textSize,
      attrs: { for: _vm.name }
    },
    [
      _vm._t("default", [_vm._v("Label")]),
      _vm.mandatory
        ? _c(
            "span",
            { staticClass: "text-red-600 font-bold mb-2 select-none" },
            [_vm._v("\n    " + _vm._s("*") + "\n  ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }