var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center flex flex-col lg:w-2/5 md:w-1/2 w-full" },
    [
      _c("h1", { staticClass: "text-xl font-black mb-12" }, [
        _vm._v("\n    Start video call\n  ")
      ]),
      _c("label", { attrs: { for: "phone" } }, [
        _vm._v("Send invite to pet owner:")
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c(
          "div",
          { staticClass: "flex flex-col md:flex-row" },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedCountryCode,
                    expression: "selectedCountryCode"
                  }
                ],
                staticClass:
                  "md:mr-4 my-1 h-12 w-full md:w-40 bg-white country-code-select",
                attrs: {
                  disabled: !_vm.countryCodes || !_vm.countryCodes.length
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedCountryCode = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                !_vm.countryCodes || !_vm.countryCodes.length
                  ? _c("option", { domProps: { value: null } }, [
                      _vm._v("\n          Loading...\n        ")
                    ])
                  : _vm._l(_vm.countryCodes, function(country) {
                      return _c(
                        "option",
                        { key: country.id, domProps: { value: country } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                "+" +
                                  country.code +
                                  " (" +
                                  country.country +
                                  ")"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    })
              ],
              2
            ),
            _c("secondary-input", {
              attrs: {
                id: "phone",
                placeholder: "Phone number",
                name: "phone",
                type: "tel",
                autofocus: ""
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.createRoom()
                }
              },
              model: {
                value: _vm.phone_nr,
                callback: function($$v) {
                  _vm.phone_nr = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "phone_nr"
              }
            })
          ],
          1
        )
      ]),
      _vm.errorMessage
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-row mt-2 p-2 bg-white border border-red-500 rounded"
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-4 ml-2 text-lg self-center text-red-500",
                attrs: { icon: "exclamation-circle" }
              }),
              _c("p", { staticClass: "self-center text-base" }, [
                _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "primary-button",
        {
          staticClass: "mt-3 flex justify-between items-center",
          attrs: { disabled: _vm.loading },
          on: {
            click: function($event) {
              return _vm.createRoom()
            }
          }
        },
        [
          _vm._v("\n    Start video call\n    "),
          _c("base-spinner", { attrs: { loading: _vm.loading } }),
          !_vm.loading
            ? _c("font-awesome-icon", { attrs: { icon: "angle-right" } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }