const DE_COUNTRY_ID = 276;
export default {
  state: {
    countryCodes: [],
    termsOfService: {},
    privacyPolicy: {},
  },
  getters: {
    getCountryCodes: state => state.countryCodes,
    getTermsOfService: state => state.termsOfService,
    getPrivacyPolicy: state => state.privacyPolicy,
  },
  mutations: {
    setCountryCodes: (state, payload) => {
      state.countryCodes = payload;
    },
    setTermsOfService: (state, payload) => {
      state.termsOfService = payload;
    },
    setPrivacyPolicy: (state, payload) => {
      state.privacyPolicy = payload;
    },
  },
  actions: {
    getCountryCodes({ commit }) {
      return axios
        .get('/api/country-codes')
        .then(res => {
          commit('setCountryCodes', res.data.country_codes);
        })
        .catch(e => console.error(e));
    },
    getTermsOfService({ commit }) {
      return axios({
        url: `/${DE_COUNTRY_ID}/tos`,
        baseURL: process.env.VUE_APP_CMS_URL,
      })
        .then(res => {
          commit('setTermsOfService', res.data.data[0]);
        })
        .catch(e => console.error(e));
    },
    getPrivacyPolicy({ commit }) {
      return axios({
        url: `/${DE_COUNTRY_ID}/privacy-policy`,
        baseURL: process.env.VUE_APP_CMS_URL,
      })
        .then(res => {
          commit('setPrivacyPolicy', res.data.data[0]);
        })
        .catch(e => console.error(e));
    },
  },
};
