<template>
  <component
    :is="type"
    :to="to"
    :disabled="disabled"
    class="primary-btn btn bg-firstvet-pink text-white tracking-wide py-3"
    :class="{'opacity-50 cursor-not-allowed': disabled}"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
import BaseButton from './BaseButton';

export default {
  extends: BaseButton,
};
</script>
<style lang="postcss" scoped>
.primary-btn:enabled:hover {
  @apply opacity-85;
}
</style>
