import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import guards from './guards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  guards.requireAuth(to, from, next);
});

export default router;
